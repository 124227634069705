import React, { Component } from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import ProductCard from '../core/ProductCard';
import BlueLineImg from '../../resources/images/brewery/blue-line.svg';
import { Fade } from 'react-reveal';

const WhiteBlockContainer = styled.div`
    width: 100%;
    background: white;
    padding-left: 0px;
    padding-right: 0px;
`;
const ContentContainer = styled(Container)`
    padding-top: 30px;
    padding-bottom: 100px;    
    box-sizing: border-box;
    @media(max-width: 768px) {
        padding-bottom: 50px;
        padding-top: 40px;
    }
`;

const RowStyled = styled(Row)`
    padding-bottom: 40px;
    padding-top: ${props => props.filterRow ? '0px' : '40px'};
    margin-left: 0px;
    margin-right: 0px;
    max-width: ${props => props.filterRow ? '540px' : ''};
    margin: auto;
`;

const ColStyled = styled(Col)`
    box-sizing: border-box;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    text-align: ${props => props.center ? 'center' : ''};
    @media(max-width: 768px) {
        order: ${props => props.controlled0 ? '0' : ''};
        order: ${props => props.controlled1 ? '1' : ''};
    };
    @media(min-width: 992px) {
        padding-top: ${props => props.middle === true ? '120px' : '0px'};
    }
    padding-bottom: ${props => props.productCol ? '45px' : ''};
`;
const Description = styled.div`
    font-size: 15px;
    line-height: 21px;
    ${fonts.gilroyRegular};
    color: #222E3C;
    text-align: center;
    max-width: 554px;
    margin: auto;
    padding-left: 10%;
    padding-right: 10%;
`;
const Filter = styled.button`
    text-decoration: none;
    border: none;
    background: transparent;
    border-radius: none;
    ${fonts.swissBlackExtended};
    text-transform: uppercase;
    color: ${props => props.active ? "#222E3C" : "#9CA8B5"};
    cursor: pointer;
    padding: 10px 8px;
    font-size: 16px;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        background-color: transparent;
        color: #222E3C;
    }
    @media(max-width: 768px) {
       font-size: 12px;
    }
`;
const BlueLine = styled.img`
    width: 80px;
    text-align: center;
    padding-top: 40px;
`;
const FilterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
`;

class WhiteBlock extends Component {
    render() {
        const { products, category, setCategory } = this.props;
        const filteredProducts = products.filter((product => product.productType.toLowerCase().includes(category)))
        return (
            <WhiteBlockContainer>
                <ContentContainer>
                    <RowStyled>
                        <ColStyled center>
                            <Description>
                                Lost at Sea brings you a carefully curated range of authentic goods inspired by our
                                passion for the British coast. Everything we offer has the same Lost at Sea spirit of
                                bravery, belief and a burning desire to go further. Those seeking samey and safe should
                                look elsewhere.
                            </Description>
                            <BlueLine src={BlueLineImg} />
                        </ColStyled>
                    </RowStyled>
                    <RowStyled filterRow>
                        <FilterContainer>
                            <Filter onClick={() => setCategory('')} active={category === ''}>All</Filter>
                            <Filter onClick={() => setCategory('beer')} active={category === 'beer'}>Beer</Filter>
                            <Filter onClick={() => setCategory('apparel')} active={category === 'apparel'}>Apparel</Filter>
                            <Filter onClick={() => setCategory('accessories')} active={category === 'accessories'}>Accessories</Filter>
                            <Filter onClick={() => setCategory('snacks')} active={category === 'snacks'}>Snacks</Filter>
                        </FilterContainer>
                    </RowStyled>
                    <RowStyled>
                        {filteredProducts
                            .map((product, index) => {
                                const position = index % 3;
                                return (
                                    <ColStyled sm={12} md={6} lg={4} key={index} middle={index === 1 || index % 3 === 1 ? true : false} productCol>
                                        <Fade duration={400} delay={position * 250}>
                                            <ProductCard product={product} isPlp/>
                                        </Fade>
                                    </ColStyled>
                                );
                            })}
                    </RowStyled>
                </ContentContainer>
            </WhiteBlockContainer>
        )
    }
}

export default WhiteBlock;
