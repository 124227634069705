function filterBySearchQuery(products, search) {
  if (!search) {
    return products;
  }

  search = search.toLowerCase();

  let filteredProducts = [];

  products.forEach((product) => {
    let includeProduct = false;

    let searchableData = [
      product.title,
      product.handle,
      product.description,
      product.productType
    ];

    const tags = product.tags;

    if (tags) {
      searchableData = searchableData.concat(tags);
    }

    const options = product.options;

    if (options) {
      product.options.forEach((option) => {
        searchableData = searchableData.concat(option.values);
      });
    }

    searchableData.forEach((searchableString) => {
      const searchString = searchableString.toLowerCase();

      if (searchString.includes(search)) {
        includeProduct = true;

        return false;
      }
    });

    if (includeProduct) {
      filteredProducts.push(product);
    }
  });

  return filteredProducts;
}

module.exports = {
  filterBySearchQuery: filterBySearchQuery
};
