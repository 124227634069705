import React, {useState} from 'react';
import styled from 'styled-components';
import Layout from '../components/core/Layout';
import SEO from '../components/core/Seo';
import Hero from '../components/store/Hero';
import WhiteBlock from '../components/store/WhiteBlock';
import {graphql} from "gatsby"
import * as queryString from "query-string"
import { filterBySearchQuery } from "../services/collection/filter"
import PrivateRoute from "../router/PrivateRoute"

const LayoutStyled = styled(Layout)`
  margin: 0px;
`;

const StorePage = (props) => {
  const path = props.location.pathname
  const products = props.data.collection.products
  const { data } = props;
  const page = data.collection

  const { q } = queryString.parse(props.location.search)

  let filteredProducts = filterBySearchQuery(products, q)

  const [category, setCategory] = useState("");

  return (
      <LayoutStyled>
       <SEO title={"Onine Shop"}
            description={page.description}
            fullTitle={true}
             path={path} />
        <Hero />
        <WhiteBlock products={filteredProducts} category={category} setCategory={setCategory}/>
      </LayoutStyled>
  )
}

function store(props) {
  return <PrivateRoute component={StorePage} {...props}/>
}

export default store;

export const pageQuery = graphql`
  query CollectionTemplateQuery {
    collection: shopifyCollection(handle: {eq: "all"}) {
      id
      title
      description
      descriptionHtml
      products {
        id
        handle
        title
        description
        productType
        tags
        images {
          id
          localFile {
            url
          }
        }
        variants {
          id
          shopifyId
          selectedOptions {
            name
            value
          }
        }
        options {
          id
          values
          name
          shopifyId
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
        }
      }
    }
  }
`

